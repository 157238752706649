import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import colors from '../utils/colors';
import { media } from '../utils/media';
import { Layout, Banner, SideBar, SEO } from '../components';

const ResidentsPage = ({ data }) => {
  const { data: residents } = data.prismicResidents;
  return (
    <Layout>
      <SEO
        description="Meet our current residents at ATSU and Still OPTI Residency"
        keywords="ATSU,Still OPTI Residency, residency, doctors, student, medical school, residents, students"
      />
      <Banner data={residents} />
      <ContentWrapper>
        <Main>
          {residents.page_content.map((section, index) => (
            <FacultyType key={index}>
              <PageHeader>{section.primary.team_section.text}</PageHeader>
              {section.items.map((item, index) => (
                <Person key={index}>
                  <PersonImage src={item.portrait.url} alt={item.portrait.alt} />
                  <PersonInfo>
                    <PersonName>{item.first_and_lastname.text}</PersonName>
                    <PersonPosition>{item.position.text}</PersonPosition>
                    <PersonBio
                      dangerouslySetInnerHTML={{
                        __html: item.biography.html,
                      }}
                    />
                  </PersonInfo>
                </Person>
              ))}
            </FacultyType>
          ))}
        </Main>
        <SideBar />
      </ContentWrapper>
    </Layout>
  );
};

export default ResidentsPage;

const ContentWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;

  @media ${media.tablet} {
    flex-direction: column;
  }
`;

const Main = styled.main`
  max-width: 950px;
  width: 100%;
  margin: 1rem 2rem 5rem 0;

  @media ${media.tablet} {
    margin: 2rem auto;
  }
`;

const FacultyType = styled.div`
  margin: 4rem 0;

  @media ${media.tablet} {
    margin: 0 auto;
  }
`;

const PageHeader = styled.h2`
  margin-bottom: 3rem;
  text-transform: uppercase;
  color: ${colors.bigStone};
  &::after {
    display: block;
    content: '';
    width: 50px;
    height: 4px;
    background-color: ${colors.rollingStone};
  }
`;

const Person = styled.div`
  display: flex;
  margin: 1rem 0;
  padding: 1rem;

  &:nth-of-type(even) {
    background-color: ${colors.wildSand};
  }

  @media ${media.phone} {
    flex-direction: column;
  }
`;

const PersonInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;

  @media ${media.phone} {
    margin-top: 1rem;
    margin-left: 0;
  }
`;

const PersonImage = styled.img`
  max-width: 250px;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
`;

const PersonName = styled.h3`
  margin-bottom: 0.5rem;
`;

const PersonPosition = styled.h4``;

const PersonBio = styled.div`
  ul {
    margin: 0;
  }
  li {
    margin-bottom: 0.5rem;
    list-style-type: none;
  }

  h5 {
    margin-bottom: 0.1rem;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 1.2rem;
  }
`;

ResidentsPage.propTypes = {
  data: PropTypes.shape({
    prismicResidents: PropTypes.shape({
      data: PropTypes.object.isRequired,
    }),
  }).isRequired,
};

export const ResidentsQuery = graphql`
  query ResidentsQuery {
    prismicResidents {
      data {
        page_title {
          text
        }
        banner_image {
          url
          alt
        }
        page_content {
          primary {
            team_section {
              text
            }
          }
          items {
            first_and_lastname {
              text
            }
            position {
              text
            }
            biography {
              html
            }
            portrait {
              url
              alt
            }
          }
        }
      }
    }
  }
`;
